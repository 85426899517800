<template>
  <div class="centerView DeliveryTable">
    <div class="title">{{ t('deliveryManage.table.expressListBos') }}：</div>
    <div class="navBoxs">
      <div class="invoice-list">
        <a-space :size="16" style="padding-right: 16px">
          <div class="invoice-item flexTL" v-for="(item, index) in currentForm" :key="index">
            <div class="time">
              <!-- {{ it.deliveryAt || '-' }} -->
              <!-- <span class="status" :style="{ color: it.status == 10 ? '#ff6700' : '' }" v-if="it.status">【{{ it.status && t(expressStatus.find((i) => i.value == it.status).label) }}】</span> -->
              <!-- <span class="status" :style="{ color: it.status == 10 ? '#ff6700' : '' }" v-if="it.status">ceshiku】</span> -->
            </div>
            <div class="invoice-code">【{{ item.expressName || '-' }}】</div>
            <div class="invoice-code" style="margin-top: 4px">
              {{ item.expressCode || '-' }}
            </div>
            <!-- <span class="invoice-code">【{{ item.expressName || '-' }}】</span> -->
            <!-- <span class="invoice-code">12321312313</span> -->
          </div>
          <div class="invoice-add flexC" @click="addExpressFn">
            <img class="icon" src="@/assets/supplier/images/deliveryManage/express-add-icon.png" />
            <span class="text">{{ t('orderManageDetail.DeliveryTable.expressAddLabel') }}</span>
          </div>
        </a-space>
      </div>
      <div v-show="table1dataSource.length" class="flexR">
        <div class="operation-button">
          <a-space :size="16">
            <a-button type="primary" size="large" class="padding-horizontal-12" style="margin-right: 42px" @click="printStockClick">{{
              t('orderManageDetail.DeliveryTable.printTheStockList')
            }}</a-button>
            <a-button type="primary" size="large" class="padding-horizontal-12" style="margin-right: 42px" @click="oneClickDelivery" :loading="oneClickDeliverySubmitting">{{
              t('orderManageDetail.DeliveryTable.oneClickDelivery')
            }}</a-button>
          </a-space>
        </div>
      </div>
    </div>

    <a-table
      v-if="!tableLoading"
      :dataSource="table1dataSource"
      :columns="columns"
      :loading="tableLoading"
      :pagination="false"
      row-key="id"
      size="small"
      class="ant-table-striped"
      :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      :scroll="{ x: 1300 }"
    >
      <template #productPicTemplate="{ text }">
        <ImgGallery v-if="text" :urls="[text]" :img-width="39" :img-height="52" :show-img-num="1" :show-more="false" />
        <span v-else>-</span>
      </template>
      <template #productName="{ text }">
        {{ text || '-' }}
      </template>
      <template #sku="{ text }">
        {{ text || '-' }}
      </template>
      <template #productParams="{ text }">
        <!-- <ImgGallery /> -->
        {{ (JSON.parse(text || '{}') || {}).color_name }} / {{ (JSON.parse(text || '{}') || {}).size_name }}
      </template>
      <template #manufacturingNo="{ text }">
        {{ text || '-' }}
      </template>
      <template #dissentQuantity="{ text }">
        {{ text || 0 }}
      </template>
      <template #deliveryQuantity="{ text }">
        {{ text || 0 }}
      </template>
      <template #quantityIncomGoods="{ record }">
        {{ record.receiptQuantity || '--' }}
      </template>
      <template #dissentQuantityTemp="{ record }">
        <a-input
          v-model:value="record.dissentQuantityInput"
          allowClear
          size="large"
          :suffix="t('orderManageDetail.DeliveryTable.table.unit')"
          :disabled="!(record.dissentQuantity - record.deliveryQuantity)"
          style="width: 140px"
          :placeholder="`${t('orderManageDetail.ReceiveOrderTable.table.pleaseInput')}${record.dissentQuantity - record.deliveryQuantity}`"
        />
        <div v-show="record.dissentQuantityInput > record.dissentQuantity - record.deliveryQuantity" style="font-size: 12px; color: #f53838">
          {{ t('orderManageDetail.DeliveryTable.table.dissentQuantityTips1') }}{{ record.dissentQuantity - record.deliveryQuantity }}{{ t('orderManageDetail.DeliveryTable.table.unit') }}
        </div>
        <div v-show="record.dissentQuantityInput < 0" style="font-size: 12px; color: #f53838">{{ t('orderManageDetail.DeliveryTable.table.dissentQuantityTips2') }}</div>
      </template>
      <template #shippedQuantity="{ record }">
        <div>{{ record.dissentQuantityTemp }}</div>
      </template>
    </a-table>

    <TableSkeleton v-if="tableLoading" />

    <AddExpressModal ref="AddExpressModalRef" v-model:modalVisible="modalVisible" v-model:submitting="submitting" @submit="(e) => createExpressFn(e)"></AddExpressModal>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, reactive, watch } from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { message } from 'ant-design-vue'
import { LoadingOutlined } from '@ant-design/icons-vue'
import { transformColumnsI18n, filterInitialColumnsFn } from '@/language'
import pagination from '@/components/pagination'
import TableSkeleton from '@/views/supplier/components/TableSkeleton'
import AddExpressModal from '@/views/supplier/components/AddExpressModal'
import ImgGallery from '@/views/supplier/components/ImgGallery'

import { createShipping } from '@/api/supplier/orderManagement/orderManage'

export default {
  name: 'DeliveryTable',
  components: {
    pagination,
    TableSkeleton,
    ImgGallery,
    AddExpressModal,
    LoadingOutlined,
  },
  props: {
    tableLoading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {}
      },
    },
    otherData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  emits: ['onPageChange', 'onSizeChange', 'getData'],
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const id = route.query.id
    const { t, locale } = useI18n()

    const isEdit = ref(false)

    /*  */
    const initialColumns = [
      {
        title: 'orderManageDetail.DeliveryTable.table.productPicTemplate',
        dataIndex: 'productPicTemplate',
        key: 'productPicTemplate',
        width: 70,
        slots: { customRender: 'productPicTemplate' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.manufacturingNo',
        dataIndex: 'manufacturingNo',
        key: 'manufacturingNo',
        width: 150,
        slots: { customRender: 'manufacturingNo' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.productParams',
        dataIndex: 'productParams',
        key: 'productParams',
        width: 150,
        slots: { customRender: 'productParams' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.dissentQuantity',
        dataIndex: 'dissentQuantity',
        key: 'dissentQuantity',
        width: 100,
        slots: { customRender: 'dissentQuantity' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.deliveryQuantity',
        dataIndex: 'deliveryQuantity',
        key: 'deliveryQuantity',
        width: 100,
        slots: { customRender: 'deliveryQuantity' },
      },
      //判断待发货、完成订单增加到货数量字段
      {
        title: 'orderManageDetail.DeliveryTable.table.quantityIncomGoods',
        dataIndex: 'quantityIncomGoods',
        key: 'quantityIncomGoods',
        width: 100,
        slots: { customRender: 'quantityIncomGoods' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.dissentQuantityTemp',
        dataIndex: 'dissentQuantityTemp',
        key: 'dissentQuantityTemp',
        width: 150,
        slots: { customRender: 'dissentQuantityTemp' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.receiptQuantity',
        dataIndex: 'receiptQuantity',
        key: 'receiptQuantity',
        width: 150,
        slots: { customRender: 'receiptQuantity' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.unqualifiedQuantity',
        dataIndex: 'unqualifiedQuantity',
        key: 'unqualifiedQuantity',
        width: 150,
        slots: { customRender: 'unqualifiedQuantity' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.sku',
        dataIndex: 'sku',
        key: 'sku',
        width: 150,
        slots: { customRender: 'sku' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.productName',
        dataIndex: 'productName',
        key: 'productName',
        // width: 150,
        slots: { customRender: 'productName' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.shippedQuantity',
        dataIndex: 'shippedQuantity',
        key: 'shippedQuantity',
        width: 100,
        slots: { customRender: 'shippedQuantity' },
      },
    ]

    const columns = ref([])
    watch(locale, () => {
      let keys = columns.value.map(({ key }) => key)
      columns.value = transformColumnsI18n(filterInitialColumnsFn(initialColumns, keys))
    })
    //表格数据
    const table1dataSource = ref([])

    watch(
      () => props.data,
      (newValue) => {
        console.log('props.data newValue :>> ', newValue)
        let { purchaseOrderDetailList = [], shippingBos = [] } = newValue
        table1dataSource.value = purchaseOrderDetailList.map((item) => {
          item.dissentQuantityInput = ''
          item.dissentQuantityTemp = item.dissentQuantity - item.deliveryQuantity
          return item
        })
      }
    )
    let filterKeys = [
      'productPicTemplate',
      'manufacturingNo',
      'productParams',
      'dissentQuantity',
      'deliveryQuantity',
      'quantityIncomGoods',
      'sku',
      'shippedQuantity',
      'dissentQuantityTemp',
      'productName',
    ]
    columns.value = transformColumnsI18n(filterInitialColumnsFn(initialColumns, filterKeys))
    // 添加快递
    const AddExpressModalRef = ref() //添加快递弹框ref
    const submitting = ref(false) //提交状态
    const modalVisible = ref(false) //弹框控制显示
    const currentForm = ref([]) //存储添加的快递信息

    //点击显示添加快递弹框
    function addExpressFn({ id, shippingSn, businessId }) {
      AddExpressModalRef.value.addExpress()
    }

    //快递弹框点击确认存储数据到页面数组
    const createExpressFn = (e) => {
      console.log(e, 'eeee')
      currentForm.value.push({ ...e })
      submitting.value = false
      modalVisible.value = false
    }

    // 一键发货
    const oneClickDeliverySubmitting = ref(false)
    const oneClickDelivery = () => {
      if (currentForm.value.length == 0) {
        message.error(t('orderManageDetail.DeliveryTable.addressNum'))
        return
      }
      if (table1dataSource.value.some(({ dissentQuantityInput, dissentQuantity, deliveryQuantity }) => dissentQuantityInput > dissentQuantity - deliveryQuantity)) return
      if (table1dataSource.value.some(({ dissentQuantityInput }) => dissentQuantityInput < 0)) return
      if (table1dataSource.value.every(({ dissentQuantityInput }) => !dissentQuantityInput)) return
      oneClickDeliverySubmitting.value = true
      let purchaseOrderDetailShippingList = table1dataSource.value
        .filter(({ dissentQuantityInput }) => dissentQuantityInput)
        .map(({ id, dissentQuantityInput }) => ({ purchaseOrderDetailId: id, dissentQuantity: dissentQuantityInput }))
      let payload = {
        quick: true,
        purchaseOrderId: props.otherData.id,
        shippingExpressListVo: currentForm.value,
        purchaseOrderDetailShippingList,
      }
      createShipping(payload)
        .then((res) => {
          if (res.code == 200) {
            oneClickDeliverySubmitting.value = false
            message.success('Success')
            // router.push({ path: '/supplier-system/orderManagement/deliveryManage' })
          }
        })
        .catch(() => {
          oneClickDeliverySubmitting.value = false
        })
    }

    onBeforeRouteLeave((to, from, next) => {
      to.meta.isEdit = isEdit.value
      to.meta.isBack = true
      next()
    })
    //打印备货单
    const printStockClick = () => {
      // router.push({
      //   path: '/printDeliver',
      //   query: { id },
      // })
      window.open(`${window.location.origin}/printDeliver?id=${id}`)
    }
    return {
      t,
      currentForm,
      columns,
      table1dataSource,
      AddExpressModalRef,
      addExpressFn,
      submitting,
      modalVisible,
      createExpressFn,
      oneClickDelivery,
      oneClickDeliverySubmitting,
      printStockClick,
      // tableResult,
      // onPageChange,
      // onSizeChange,
    }
  },
}
</script>

<style lang="less" scope>
.title {
  font-size: 18px;
  padding: 8px 0;
}
.navBoxs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DeliveryTable {
  .order-code {
    height: 40px;
    flex: 1;
    border-radius: 8px;
    background-color: #fafafa;
    color: #131011;
    font-size: 16px;
    padding: 0 16px 0 20px;

    img {
      display: inline-block;
      width: 16px;
      margin-right: 20px;
    }
  }
  .invoice-list {
    margin-bottom: 16px;
    // overflow: hidden;
    overflow-x: auto;
    .invoice-item,
    .invoice-add {
      height: 68px;
      padding: 14px 16px;
      border: 1px solid #fee5d4;
      border-radius: 4px;
      background-color: #fff5ec;
      line-height: 1;
      min-width: 140px;

      .time {
        color: #ff6700;
        font-weight: 400;
        font-size: 14px;
        white-space: nowrap;
      }

      .invoice-code {
        color: #131011;
        font-weight: 300;
        font-size: 14px;
      }

      .icon {
        width: 24px;
        margin-right: 12px;
      }

      .text {
        color: #ff6700;
        font-weight: 300;
        font-size: 14px;
      }
    }
    .invoice-add {
      display: flex;
      cursor: pointer;
    }
  }
  .ant-table-striped {
    background-color: #fff;
  }
}
</style>
